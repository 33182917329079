import { ReactNode, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import invariant from 'tiny-invariant';
import HistoryDisplaySettings from '../../ui/history/history-display-settings';
import usePlugins, { PluginToken } from '../../util/plugin/use-plugins';

type LandingPageModuleDisplaySettings<T extends FieldValues> = Partial<
  Pick<HistoryDisplaySettings<T>, 'attributeLabels' | 'valueFormatter' | 'diffEntireWord'>
>;

export default interface LandingPageModuleConfig<TSettings extends FieldValues, TContent extends FieldValues> {
  type: string;
  label: string;
  renderSettingsControl(props: { name: `moduleSettings.${number}` }): ReactNode;
  renderContentControl(props: { name: `modules.${number}` }): ReactNode;
  renderSettingsViewer({ module }: { module: TSettings }): ReactNode;
  settingsHistoryDisplaySettings: LandingPageModuleDisplaySettings<TSettings>;
  contentHistoryDisplaySettings: LandingPageModuleDisplaySettings<TContent>;
}

export interface LandingPageModuleConfigHook<TSettings extends FieldValues, TContent extends FieldValues> {
  (): LandingPageModuleConfig<TSettings, TContent>;
}

export const LANDING_PAGE_MODULE_HOOK = new PluginToken<LandingPageModuleConfigHook<any, any>>('LandingPageModuleHook');

export function useLandingPageModuleConfigs() {
  const moduleConfigHooks = usePlugins(LANDING_PAGE_MODULE_HOOK);
  // Hooks should never be called conditionally. So this is actually a no-go. But: the list of
  // plugins returned by usePlugins is static. Therefor the order in which the hooks are called is
  // always the same. Just not wrap it in useMemo and everything should work out fine.
  const moduleConfigs = moduleConfigHooks.map((hook) => hook());

  return useMemo(
    () => ({
      moduleConfigs,
      getModuleConfig: (type: string) => {
        const extension = moduleConfigs.find((extension) => type === extension.type);
        invariant(extension != null, `no type extension found for landing page module type ${type}`);
        return extension;
      },
    }),
    [moduleConfigs],
  );
}
