import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const invoiceMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.invoice" />,
    path: '/invoices',
    neccessaryPermission: 'INVOICE:CAN_SEE_LIST_VIEW',
  },
  {
    name: <Translate ns="app" i18nKey="main_navigation.accounting_record" />,
    path: '/accounting-records',
    neccessaryPermission: 'ACCOUNTING_RECORD:CAN_SEE_LIST_VIEW',
  },
];

export default invoiceMenuItems;
