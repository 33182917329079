/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { LandingPageDesignBodyDto } from './LandingPageDesignBodyDto';
import {
    LandingPageDesignBodyDtoFromJSON,
    LandingPageDesignBodyDtoFromJSONTyped,
    LandingPageDesignBodyDtoToJSON,
} from './LandingPageDesignBodyDto';
import type { LandingPageDesignFooterDto } from './LandingPageDesignFooterDto';
import {
    LandingPageDesignFooterDtoFromJSON,
    LandingPageDesignFooterDtoFromJSONTyped,
    LandingPageDesignFooterDtoToJSON,
} from './LandingPageDesignFooterDto';
import type { LandingPageDesignHeaderDto } from './LandingPageDesignHeaderDto';
import {
    LandingPageDesignHeaderDtoFromJSON,
    LandingPageDesignHeaderDtoFromJSONTyped,
    LandingPageDesignHeaderDtoToJSON,
} from './LandingPageDesignHeaderDto';
import type { LandingPageDesignStatusDto } from './LandingPageDesignStatusDto';
import {
    LandingPageDesignStatusDtoFromJSON,
    LandingPageDesignStatusDtoFromJSONTyped,
    LandingPageDesignStatusDtoToJSON,
} from './LandingPageDesignStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface LandingPageDesignDto
 */
export interface LandingPageDesignDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof LandingPageDesignDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof LandingPageDesignDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof LandingPageDesignDto
     */
    brand: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof LandingPageDesignDto
     */
    edition?: EditionReferenceDto;
    /**
     * 
     * @type {LandingPageDesignStatusDto}
     * @memberof LandingPageDesignDto
     */
    status: LandingPageDesignStatusDto;
    /**
     * 
     * @type {LandingPageDesignHeaderDto}
     * @memberof LandingPageDesignDto
     */
    header: LandingPageDesignHeaderDto;
    /**
     * 
     * @type {LandingPageDesignBodyDto}
     * @memberof LandingPageDesignDto
     */
    body: LandingPageDesignBodyDto;
    /**
     * 
     * @type {LandingPageDesignFooterDto}
     * @memberof LandingPageDesignDto
     */
    footer: LandingPageDesignFooterDto;
}

/**
 * Check if a given object implements the LandingPageDesignDto interface.
 */
export function instanceOfLandingPageDesignDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('status' in value)) return false;
    if (!('header' in value)) return false;
    if (!('body' in value)) return false;
    if (!('footer' in value)) return false;
    return true;
}

export function LandingPageDesignDtoFromJSON(json: any): LandingPageDesignDto {
    return LandingPageDesignDtoFromJSONTyped(json, false);
}

export function LandingPageDesignDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageDesignDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'brand': json['brand'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'status': LandingPageDesignStatusDtoFromJSON(json['status']),
        'header': LandingPageDesignHeaderDtoFromJSON(json['header']),
        'body': LandingPageDesignBodyDtoFromJSON(json['body']),
        'footer': LandingPageDesignFooterDtoFromJSON(json['footer']),
    };
}

export function LandingPageDesignDtoToJSON(value?: LandingPageDesignDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'brand': value['brand'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'status': LandingPageDesignStatusDtoToJSON(value['status']),
        'header': LandingPageDesignHeaderDtoToJSON(value['header']),
        'body': LandingPageDesignBodyDtoToJSON(value['body']),
        'footer': LandingPageDesignFooterDtoToJSON(value['footer']),
    };
}

