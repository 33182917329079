import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { landingPageIcon } from '../../ui/icons/business-objects';
import { PageTab } from '../../ui/page';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { LANDING_PAGE_DESIGN_STATUS_BLOCKER_RENDERER } from '../landing-page-design/common/landing-page-design-status-toggle-menu-item';
import { LANDING_PAGE_TEMPLATE_VIEWER_TAB } from '../landing-page-template/landing-page-template-plugins';
import RsvpMailingModuleControl from '../mailing/mailing-editor/mailing-module/rsvp-mailing-module-control';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing/mailing-module-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import LandingPageReference from './common/landing-page-reference';
import { useLandingPagePermissionClassGroupExtension } from './landing-page-permissions';
import { LandingPageSearchResult } from './landing-page-search-result';

export default function LandingPagePluginProvider(registry: PluginRegistry) {
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'RSVP',
    moduleControl({ template, module, name, connectionId }) {
      invariant(module.moduleType === 'RSVP', 'Type must be rsvp');
      invariant(connectionId != null, 'Event must not be null');

      return <RsvpMailingModuleControl eventId={connectionId} name={name} mailingType={template.type} />;
    },
  });

  registry.register(LANDING_PAGE_DESIGN_STATUS_BLOCKER_RENDERER, (blocker) => (
    <LandingPageReference reference={blocker} />
  ));

  registry.register(LANDING_PAGE_TEMPLATE_VIEWER_TAB, {
    route: {
      path: 'landing-pages',
      lazy: () => import('./landing-page-lister/landing-page-template-usages-route'),
    },
    tab: <LandingPageTemplateUsagesTab />,
  });

  registry.register(SEARCH_FILTER, {
    key: 'LANDING_PAGE',
    icon: landingPageIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.LANDING_PAGE" />,
    necessaryPermission: 'LANDING_PAGE:CAN_SEE_DETAILS',
  });
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'LANDING_PAGE',
    Component: LandingPageSearchResult,
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useLandingPagePermissionClassGroupExtension);
}

function LandingPageTemplateUsagesTab() {
  const { t } = useTranslation(['landing_page']);
  return (
    <PageTab to="./landing-pages" icon={landingPageIcon}>
      {t('landing_page:lister.title')}
    </PageTab>
  );
}
