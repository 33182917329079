/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionDto } from './EditionDto';
import {
    EditionDtoFromJSON,
    EditionDtoFromJSONTyped,
    EditionDtoToJSON,
} from './EditionDto';
import type { GroupStatusDto } from './GroupStatusDto';
import {
    GroupStatusDtoFromJSON,
    GroupStatusDtoFromJSONTyped,
    GroupStatusDtoToJSON,
} from './GroupStatusDto';
import type { LanguagesDto } from './LanguagesDto';
import {
    LanguagesDtoFromJSON,
    LanguagesDtoFromJSONTyped,
    LanguagesDtoToJSON,
} from './LanguagesDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PersonReferenceDto } from './PersonReferenceDto';
import {
    PersonReferenceDtoFromJSON,
    PersonReferenceDtoFromJSONTyped,
    PersonReferenceDtoToJSON,
} from './PersonReferenceDto';
import type { PresenceDto } from './PresenceDto';
import {
    PresenceDtoFromJSON,
    PresenceDtoFromJSONTyped,
    PresenceDtoToJSON,
} from './PresenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A group of persons.
 * @export
 * @interface GroupDto
 */
export interface GroupDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof GroupDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    name: string;
    /**
     * 
     * @type {Array<OwnerDto>}
     * @memberof GroupDto
     */
    owners: Array<OwnerDto>;
    /**
     * 
     * @type {Array<PersonReferenceDto>}
     * @memberof GroupDto
     */
    externalGroupAdmins: Array<PersonReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof GroupDto
     */
    capacity?: number;
    /**
     * 
     * @type {EditionDto}
     * @memberof GroupDto
     */
    edition: EditionDto;
    /**
     * 
     * @type {Array<LanguagesDto>}
     * @memberof GroupDto
     */
    languages?: Array<LanguagesDto>;
    /**
     * 
     * @type {Array<PresenceDto>}
     * @memberof GroupDto
     */
    presence?: Array<PresenceDto>;
    /**
     * 
     * @type {number}
     * @memberof GroupDto
     */
    juniorAssistants?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupDto
     */
    seniorAssistants?: number;
    /**
     * 
     * @type {GroupStatusDto}
     * @memberof GroupDto
     */
    status: GroupStatusDto;
}

/**
 * Check if a given object implements the GroupDto interface.
 */
export function instanceOfGroupDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('externalGroupAdmins' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function GroupDtoFromJSON(json: any): GroupDto {
    return GroupDtoFromJSONTyped(json, false);
}

export function GroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'owners': ((json['owners'] as Array<any>).map(OwnerDtoFromJSON)),
        'externalGroupAdmins': ((json['externalGroupAdmins'] as Array<any>).map(PersonReferenceDtoFromJSON)),
        'capacity': json['capacity'] == null ? undefined : json['capacity'],
        'edition': EditionDtoFromJSON(json['edition']),
        'languages': json['languages'] == null ? undefined : ((json['languages'] as Array<any>).map(LanguagesDtoFromJSON)),
        'presence': json['presence'] == null ? undefined : ((json['presence'] as Array<any>).map(PresenceDtoFromJSON)),
        'juniorAssistants': json['juniorAssistants'] == null ? undefined : json['juniorAssistants'],
        'seniorAssistants': json['seniorAssistants'] == null ? undefined : json['seniorAssistants'],
        'status': GroupStatusDtoFromJSON(json['status']),
    };
}

export function GroupDtoToJSON(value?: GroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'owners': ((value['owners'] as Array<any>).map(OwnerDtoToJSON)),
        'externalGroupAdmins': ((value['externalGroupAdmins'] as Array<any>).map(PersonReferenceDtoToJSON)),
        'capacity': value['capacity'],
        'edition': EditionDtoToJSON(value['edition']),
        'languages': value['languages'] == null ? undefined : ((value['languages'] as Array<any>).map(LanguagesDtoToJSON)),
        'presence': value['presence'] == null ? undefined : ((value['presence'] as Array<any>).map(PresenceDtoToJSON)),
        'juniorAssistants': value['juniorAssistants'],
        'seniorAssistants': value['seniorAssistants'],
        'status': GroupStatusDtoToJSON(value['status']),
    };
}

